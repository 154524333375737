<script setup>
import Logo from "@/Components/AdminLayout/logo/LogoDark.vue";
import { useTheme } from 'vuetify'
const theme = useTheme()
</script>
<template>

    <div class="min-h-full  bg-land-background">
        <v-container class="h-full">
            <v-row class="min-h-[99vh] h-full">
                <v-col class="m-  justify-center items-center flex min-h-full">
                    <div class="md:p-1 6 my-auto w-full h-full flex flex-col justify-center">
                        <v-card class="p-9 max-h-[95vh] my-auto h-max  overflow-auto">
                             <slot />
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="min-h-full hidden md:block">
                    <div class="flex flex-col justify-center items-center text-center ">
                        <v-img src="/assets/landImages/full-logo.svg" class="w-1/3" :height="50" />
                        <div class="mt-5">
                            <h2 class="h2 font-bold">
                                {{ __t('we_connect_you') }}
                                <span class="text-primary">
                                    {{ __t('with_success') }}
                                </span>
                            </h2>
                            <h3>
                                {{ __t('to_make_difference_in_entrepreneurship') }}
                            </h3>
                        </div>
                    </div>
                    <div class="md:p-1 6 hidden md:flex md:px-10 8">
                        <v-img :alt="__t('smart_employee')" width="100%" min-height="550px" style="object-fit: cover!important;" :src="__t('auth-img')" :lazy-src="__t('auth-loader')">
                            <template v-slot:placeholder>
                                <div class="justify-center d-flex align-center fill-height">
                                    <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
                                </div>
                            </template>
                        </v-img>
                    </div>
                </v-col>
            </v-row>
        </v-container>


    </div>
</template>
