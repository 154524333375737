<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import JetAuthenticationCard from "@/Components/Cards/AuthenticationCard.vue";
import JetAuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue";
import JetButton from "@/Components/Buttons/Button.vue";
import JetInput from "@/Components/Input/Input.vue";
import JetInputError from "@/Components/Input/InputError.vue";
import JetCheckbox from "@/Components/Input/Checkbox.vue";
import JetLabel from "@/Components/Input/Label.vue";
import { defineAsyncComponent, ref } from "vue";
const ReCaptcha2 = defineAsyncComponent(() =>
  import("@/Components/Input/ReCaptcha2.vue")
);
defineProps({
  canResetPassword: Boolean,
  status: String,
});

const loginForm = ref(null);
const visible = ref(false);

const form = useForm({
  email: "",
  password: "",
  "g-recaptcha-response": "",
  remember: false,
});

const submit = () => {
  form.clearErrors();
  form
    .transform((data) => ({
      ...data,
      remember: form.remember ? "on" : "",
    }))
    .post(route("login"), {
      onFinish: () => form.reset("password"),
    });
};

const verifyMethod = (response) => {
  form["g-recaptcha-response"] = response;
};
</script>

<template>
  <Head :title="__t('login')" />
  <!-- test -->
  <JetAuthenticationCard class="">
    <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
      {{ status }}
    </div>
    <div>
      <h1 class="font-bold text-[22px] text-center h1">{{ __t("login") }}</h1>
      <br />
      <!-- <hr> -->
      <br />
    </div>

    <form @submit.prevent="submit" ref="loginForm">
      <div>
        <!-- <JetLabel for="email" :value="__t('email')" class="bblack-color-regester" /> -->
        <!-- <JetInput id="email" v-model="form.email" type="email" class="block w-full mt-2" required autofocus /> -->
        <v-text-field
          :label="__t('email')"
          type="email"
          name="email"
          variant="outlined"
          required
          v-model="form.email"
        ></v-text-field>
        <JetInputError class="mt-2" :message="form.errors.email" />
      </div>

      <div class="mt-4">
        <!-- <JetLabel for="password" :value="__t('password')" class="bblack-color-regester" /> -->
        <!-- <JetInput id="password" v-model="form.password" type="password" class="block w-full mt-2" required
                    autocomplete="current-password" /> -->
        <JetInputError class="mt-2" :message="form.errors.password" />
        <v-text-field
          :label="__t('password')"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          variant="outlined"
          required
          v-model="form.password"
          @click:append-inner="visible = !visible"
        ></v-text-field>
      </div>
      <v-no-ssr>
        <ReCaptcha2
          @verified="verifyMethod"
          :reset="!!form.errors['g-recaptcha-response']"
        >
        </ReCaptcha2
      ></v-no-ssr>

      <div class="block mt-4">
        <label class="flex items-center">
          <JetCheckbox v-model:checked="form.remember" name="remember" />
          <span class="mx-2 text-sm text-gray-600">{{
            __t("remember_me")
          }}</span>
        </label>
      </div>

      <div class="flex flex-wrap items-center justify-between mt-4">
        <div v-if="route().has('register')">
          <Link
            :href="route('register')"
            class="text-sm text-gray-600 underline hover:text-gray-900"
          >
            {{ __t("register_new_account") }}
          </Link>
        </div>
        <div>
          <Link
            v-if="canResetPassword"
            :href="route('password.request')"
            class="text-sm text-gray-600 underline hover:text-gray-900"
          >
            {{ __t("forgot_your_password") }}
          </Link>
          <JetInputError
            class="mt-2"
            :message="form.errors['g-recaptcha-response']"
          />

          <JetButton
            class="mx-4"
            :class="{ 'opacity-25': form.processing }"
            :disabled="form.processing"
          >
            {{ __t("login") }}
          </JetButton>
        </div>
      </div>
    </form>
  </JetAuthenticationCard>
</template>

<style scoped>
input:not([type="checkbox"]) {
  height: 55px;
  background-color: rgb(var(--v-theme-input-background));
  padding: 12px;
  border: 1px solid rgb(var(--v-theme-border));
}

.bblack-color-regester {
  color: black !important;
}
</style>
